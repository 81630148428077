import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import jquery from 'jquery';
window.$ = window.jQuery = jquery;
const Home = React.lazy(() => import('./views/home/view'))
const Video = React.lazy(() => import('./views/video/view'))
const Inner = React.lazy(() => import('./views/inner/view'))

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

class App extends Component {

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/" name="Home Page" render={props => <Home {...props}/>} />
            <Route path="/videos" name="Video Page" render={props => <Video {...props}/>} />
            <Route path="/inner" name="Inner Page" render={props => <Inner {...props}/>} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
